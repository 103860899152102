@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./../public/fonts/subset-Roboto-Bold.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('./../public/fonts/subset-Roboto-BlackItalic.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black'), local('Roboto-Black'),
    url('./../public/fonts/subset-Roboto-Black.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('./../public/fonts/subset-Roboto-BoldItalic.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('./../public/fonts/subset-Roboto-LightItalic.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'),
    url('./../public/fonts/subset-Roboto-Light.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('./../public/fonts/subset-Roboto-Italic.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('./../public/fonts/subset-Roboto-Medium.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
    url('./../public/fonts/subset-Roboto-Regular.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('./../public/fonts/subset-Roboto-MediumItalic.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('./../public/fonts/subset-Roboto-Thin.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('./../public/fonts/subset-Roboto-ThinItalic.woff2') format('woff2'),
    url('./../public/fonts/subset-Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
